export default {
    BusinessName: 'GoFundraise',

    DisplayNameFormat: {
        fullName: 'SHOW_FULL',
        firstNameOnly: 'FIRST_ONLY',
        anonymous: 'ANONYMOUS'
    },

    DonationContactType: {
        individual: 'I',
        company: 'B'
    },

    DonationType: {
        donation: 'D',
        depositCollectedFunds: 'P'
    },

    FeeModel: {
        standard: 'STD',
        donationTip: 'TIP',
        matchedServiceFee: "MSF"
    },

    PageType: {
      completion: 'COMPLETION',
      completionError: 'COMPLETION_ERROR',
      slide: 'SLIDE',
    },

    RegularGivingFrequency: {
        Once: "O",
        Daily: "D",
        Weekly: "W",
        Monthly: "M",
        Quarterly: "Q",
        Yearly: "Y"
    },

    TermAndConditionType : {
        Checkbox : "CHECKBOX",
        None : "NONE",
	},

    CustomQuestionType: {
        Checkbox: 'checkbox',
        PaymentMeta : 'PAYMENT_META',
        WellknownField: 'WELLKNOWN_FIELD',
    },

    CustomQuestionFieldName:{
        OptInToComms : "OptInToComms",
        SignUpToNewsletter: "SignUpToNewsletter",
    },

    CustomQuestionOptionsDisplayType: {
        Radio: "radio",
        Select: "select"
    },

    PaymentMetaType: {
        Custom: "Custom"
    },

    PaymentMetaValueType: {
        String: "String"
    },

    ModuleName: {
        CustomQuestion: "CustomQuestion"
    },
}
