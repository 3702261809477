<template>
  <div
    id="gf-custom-question"
    class="col-12 m-0 p-0"
  >
    <div class="col-12">
      <div class="rounded-checkbox row">
        <label
          :for="checkboxId"
          class="transition"
        >
          <input
            :id="checkboxId"
            v-model="checked"
            autocomplete="off"
            data-val="true"
            class=""
            :name="checkboxName"
            type="checkbox"
          >
          <div
            :class="['box', {'gf-secondary-bg gf-secondary-text': checked}]"
          /></label>
        <label
          :for="checkboxId"
          class="checkbox-detail col"
        >
          {{ settings.text }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import constants from '../../EmbeddedPaymentConstants';
import _ from "lodash";

export default {
  name: "Checkbox",
	mixins: [ModuleBase],
  props:{
      values: {
          type: Object,
          default: function () {
              return { }
          }
      }
  },
  data() {
    return {
      checked: true,
      dataType : constants.CustomQuestionType.PaymentMeta,
      dataFieldName : '',
    }
  },
  computed: {
    checkboxName() {
      return `checkbox-custom-${this.settings.uniqueIdentifier}`
    },
    checkboxId() {
      return `custom-question-checkbox-${this.settings.uniqueIdentifier}`
    },
  },
  watch: {
    checked() {
      this.logger.logInfo(`checked changed. Settings:`, this.settings)
      this.storeUpdatedValue();
    }
  },
	mounted() {
		this.logger.logInfo('Checkbox component loaded. Settings:', this.settings)
    if(this.settings.dataSettings){
      this.dataType = this.settings.dataSettings.type;
      this.dataFieldName = this.settings.dataSettings.fieldName || '';
    }
    this.checked = this.isChecked(this.settings.values);
    this.storeUpdatedValue();
	},

	methods: {
		isChecked(object){
			return _.has(object, "default") && object['default'] === 'onValue';
		},

    storeUpdatedValue() {
      this.storeUpdatedValueForMetaData();

      if(this.dataType === constants.CustomQuestionType.WellknownField){
        this.storeUpdatedValueForWellknownField();
      }
    },

    storeUpdatedValueForWellknownField(){
       if(this.dataFieldName === '') return;
       var val = this.checked;

       switch (this.dataFieldName) {
          case constants.CustomQuestionFieldName.OptInToComms:
            this.store.updateReceiveNewsletter(val);
            break;
          case constants.CustomQuestionFieldName.SignUpToNewsletter:
            this.store.updateReceiveGFNewsletter(val);
            break;
        }
    },

    storeUpdatedValueForMetaData(){
      if(this.checked){
        this.store.updateCustomQuestionValue(this.prepareMetaData('onValue'));
      }
      else{
        this.store.updateCustomQuestionValue(this.prepareMetaData('offValue'));
      }
    },

    prepareMetaData(value){
      var metaValue =
        this.settings.values &&
        this.settings.values.hasOwnProperty(value) &&
        this.settings.values[value].value !== undefined
            ? this.settings.values[value].value
            : ""

      var metaName = this.settings.uniqueIdentifier !== undefined
        ? this.settings.uniqueIdentifier
        : ""

      var metaDescription = this.settings.text !== undefined
        ? this.settings.text
        : ""

      return {
        metaType: "Custom",
        metaValueType: "String",
        name: metaName,
        description: metaDescription,
        value: metaValue
      }
    }
	},
};
</script>
