import constants from './EmbeddedPaymentConstants';
export default {
    csrfToken: '',
    regionId: null,
    beneficiaryId: null,
    fundraisingPageId: null,
    eventCampaignId: null,
    isDemo: false,
    customerDetails: {
        DonorType: 'I',
        CompanyName: '',
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        MobileNumber: '',
        ContactNumber: '',
        AddressLine1: '',
        Suburb: '',
        Postcode: '',
        State: '',
        Country: '',
        ReceiveNewsletter : false,
        ReceiveGFNewsletter : false
    },
    tokenDetailsForTransaction: {
        Token: '',
        Type: null,
        CredentialHolder: null
    },
    donationType: constants.DonationType.donation,
    donorTipBeneficiary: null,
    donorTipBeneficiaryTaxTypeId: 1,
    globalEventBus: {},
    nameDisplayFormat: constants.DisplayNameFormat.fullName,
    feeModel: constants.FeeModel.standard,
    message: null,
    additionalItems: [],
    supportedGateways: [],
    currentGateway: {},
    defaultPaymentGateway: '',
    currentGatewayTrigger: 0,
    currencyInfo: {},
    gatewayLoadStatus: {
        success: true,
        msg: ''
    },
    currentPageIndex: 0,
    clientIpAddress: "",
    pageModules: [],
    amount: 0,
    addOnamount: 0,
    totalAmount: 0,
    recurringDonation: false,
    recurringDonationFrequency: 3,
    nextPaymentDate: null,
    paymentResponse: {},
    coveringCost: false,
    canProceed: false,
    metaData: [],
    entityConfigsToLoad: [],
    entityConfigResults: null,
    captchaVerified: false,
    captchaToken: '',
    waitForCompletion: true,
    pollingPeriodInMs: 1500,
    pollingTimeoutInMs: 180000,
    isProcessingPayment: false,
    isPendingPaymentActions: false,
    pendingPaymentActionsData: null,
    clientActionsResponse: null,
    readyForSubmission: false,
    forceContactDetailsAsAnonymous: false,
    prefillData: [],
    paymentClientReference: null,
    showAlternateButton: false,
    beneficiaryData: null,
    calculateTipAmountTrigger: 0,
    cdnBaseUrl: '',
    regenerateTokenTrigger: 0,
    userToken: '',
    isPreview: false,
}
