import logger from './logger';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import EmbeddedPaymentStore from '../components/embedded-payment-loader/EmbeddedPaymentStore'
import paymentGatewaySettings from '../data/paymentGatewaySettings'
import retrievePaymentStatusClient from './payment-service-client/retrievePaymentStatusClient';
import updatePaymentRequestClient from './payment-service-client/updatePaymentRequestClient';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay, retryCondition: (e) => {
    return !e.response || (e.response.status >= 500 && e.response.status < 600) }
});

export default {
    apiDomain: '',

    setApiDomain(apiDomain) {
        logger.logInfo(`Api Domain set to: ${this.apiDomain}`)
        this.apiDomain = apiDomain;
    },

    async retrievePaymentModuleData(beneficiaryId, eventId, type) {
        logger.logInfo(`making call out to ${this.apiDomain}, beneficiaryId: ${beneficiaryId}, eventId: ${eventId}`);
        try {
            var results = {}

            if (EmbeddedPaymentStore.state.isDemo) {
              switch (type) {
                case 'paypal-credit-card':
                case 'paypal-paypal':
                  results = await new Promise(resolve => {
                    resolve(paymentGatewaySettings.PayPal)
                  })
                  break
                case 'braintree-credit-card':
                case 'braintree-paypal':
                case 'braintree-googlepay':
                case 'braintree-applepay':
                  results = await new Promise(resolve => {
                    resolve(paymentGatewaySettings.BrainTree)
                  })
                  break;
                case 'clicktopay-credit-card':
                  results = await new Promise(resolve => {
                    resolve(paymentGatewaySettings.ClickToPay)
                  })
                  break;
                default:
                  results = await new Promise(resolve => {
                    resolve(paymentGatewaySettings.BrainTree)
                  })
                  break
              }
            } else {
              results = await axios.post(`https://${this.apiDomain}/v1/payments/paymentGatewaySettings`,
              {
                  "BeneficiaryIds": [beneficiaryId],
                  "EventCampaignId": eventId,
                  "UserToken": EmbeddedPaymentStore.state.userToken
              });
            }
            
        } catch (error) {
            console.log(error);
            return {
                status: {
                    success: false,
                    msg: 'Unable to load the payment gateway settings. Please retry in a few minutes by refreshing the page. If the problem persists, please contact support on 1300 889 272 (or +61 2 8188 8920).'
                },
                gatewaySettings: {}
            }
        }
        if (results.data && results.data.gatewayTypes && results.data.gatewayTypes.length === 0) {
            return {
                status: {success: false, msg: 'No available payment gateways for checkout. Please retry in a few minutes by refreshing the page. If the problem persists, please contact support on 1300 889 272 (or +61 2 8188 8920).'},
                gatewaySettings: results.data
            };
        }
        return {
            status: {success: true, msg: ''},
            gatewaySettings: results.data
        };
    },

    async postDonation(request, csrfToken) {
        logger.logInfo(`Posting donation using embedded module`, request);

        var axiosAjaxOptions = {
            headers: {
                        "x-requested-with": "XMLHttpRequest",
                        "RequestVerificationToken": csrfToken
                     }
        };
        try {
            var results = await axios.post(`/payments/donate/embedded`, request, axiosAjaxOptions);
            logger.logInfo(`response: `, results);
        } catch (error) {
            return {
                data: {
                    Status: {
                        Success: false,
                        Message: 'There was an error with the payment request. Please check the entered details and try again. If the problem persists, please contact support on 1300 889 272 (or +61 2 8188 8920).'
                    }
                }
            }
        }
        return results.data;
    },

    async downloadReceipt(paymentId, paymentReference) {
        logger.logInfo(`Downloading receipt for id: ${paymentId} with reference: ${paymentReference}`);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
            }
        }

        const params = new URLSearchParams()
        params.append('PaymentId', paymentId)
        params.append('PaymentReference', paymentReference)

        var results = await axios.post(`/payments/donate/print-receipt`, params, config);
        return results;
    },

    async retrievePaymentStatus(paymentReferenceId, csrfToken) {
        return await retrievePaymentStatusClient.execute(axios, paymentReferenceId, csrfToken)
    },

    async updatePaymentRequest(paymentReferenceId, requestType, additionalData) {
        return await updatePaymentRequestClient.execute(axios, paymentReferenceId, requestType, additionalData)
    }, 
}
