<template lang="html">
  <div
    id="gf-payments-gateway"
    :class="{'col':!useEmbeddedModule}"
  >
    <component
      :is="paymentComponent"
      v-if="!useSystemDefinedGateway"
      :additional-properties="additionalProperties"
      :tokenization-key="publicKey"
      :process-button-id="processButtonId"
      :process-button-selector="processButtonSelector"
      :pending-actions-data="pendingActionsData"
      :global-event-bus="globalEventBus"
      :on-tokenize-success="onTokenizeSuccess"
      :on-token-reset="onTokenReset"
      :on-fees-received="onFeesReceived"
      :on-ready="onReady"
      :on-load-error="onLoadError"
      :on-payment-response="onPaymentResponse"
      :prefill-settings="prefillSettings"
      :payment-data="paymentData"
      :embedded-payment-mode="false"
    />
    <GfMultiGatewayLoader
      v-if="useSystemDefinedGateway && !useEmbeddedModule"
      :api-domain="apiDomain"
      :supported-gateways="supportedGateways"
      :client-ip-address="clientIpAddress"
      :additional-properties="additionalProperties"
      :process-button-id="processButtonId"
      :process-button-selector="processButtonSelector"
      :pending-actions-data="pendingActionsData"
      :global-event-bus="globalEventBus"
      :on-tokenize-success="onTokenizeSuccess"
      :on-token-reset="onTokenReset"
      :on-fees-received="onFeesReceived"
      :on-ready="onReady"
      :on-load-error="onLoadError"
      :on-payment-response="onPaymentResponse"
      :prefill-settings="prefillSettings"
      :payment-data="paymentData"
      :embedded-payment-mode="false"
    />
    <GfEmbeddedPaymentLoader
      v-if="useEmbeddedModule"
      :api-domain="apiDomain"
      :event-id="eventId"
      :beneficiary-id="beneficiaryId"
      :page-id="pageId"
      :region-id="regionId"
      :gateway-settings="gatewaySettingsResults"
      :currency-info="currencyInfoResult"
      :client-ip-address="clientIpAddress"
      :additional-properties="additionalProperties"
      :global-event-bus="globalEventBus"
      :on-tokenize-success="onTokenizeSuccess"
      :on-token-reset="onTokenReset"
      :on-fees-received="onFeesReceived"
      :on-ready="onReady"
      :on-load-error="onLoadError"
      :on-payment-response="onPaymentResponse"
      :demo="demo"
      :prefill-settings="prefillSettings"
      :payment-data="paymentData"
      :embedded-payment-mode="true"
    />
  </div>
</template>

<script>

import 'bootstrap'

import GfPaymentServiceClient from "./common/GfPaymentServiceClient";
import * as GfLocalisation from '@gf/gf-localisation'
import GfMultiGatewayLoader from "./components/multi-gateway-loader/GfMultiGatewayLoader.vue";
import GfEmbeddedPaymentLoader from "./components/embedded-payment-loader/GfEmbeddedPaymentLoader.vue";
import SupportedGateways from "./components/SupportedGateways.vue"
import embeddedPaymentStore from './components/embedded-payment-loader/EmbeddedPaymentStore'
import conditionalLogicStateStore from './common/conditionalLogic/ConditionalLogicStateStore';
import casingUtilities from './common/casingUtilities';
import logger from './common/logger';
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  name: "GfPayments",
  components: {
    GfMultiGatewayLoader,
    GfEmbeddedPaymentLoader
  },
  mixins: [SupportedGateways],
  props: {
    type: {
      type: String,
      default: "paydock"
    },
    apiDomain: {
      type: String,
      default: "api.gofundraise.com.au"
    },
    cdnBaseUrl: {
      type: String,
      default: ""
    },
    useSystemDefinedGateway: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: String,
      default: ""
    },
    beneficiaryId: {
      type: String,
      default: ""
    },
    regionId: {
      type: String,
      default: "eedd16d2-38b3-459e-b2d4-5ba898524018"
    },
    pageId: {
      type: String,
      default: ""
    },

    tokenizationKey: {
      type: String,
      default: ""
    },
    processButtonId: {
      type: String,
      default: "",
      required: true
    },
    processButtonSelector: {
      type: String,
      default: "",
    },
    onTokenizeSuccess: {
      type: String,
      default: ""
    },
    onFeesReceived: {
      type: String,
      default: ""
    },
    onTokenReset: {
      type: String,
      default: ""
    },
    onReady: {
      type: String,
      default: ""
    },
    onLoadError: {
      type: String,
      default: ""
    },
    pendingActionsData: {
        type: Object,
        default: () => null
    },
    onPaymentResponse: {
      type: String,
      default: ""
    },
    globalEventBus: {
      type: Object,
      default: () => {}
    },
    additionalProperties: {
      type: Object,
      default: () => {},
    },
    gfDesign: {
      type: String,
      default: ""
    },
    demo: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    prefillSettings: {
      type: Object,
      default: () => null
    },
    paymentAmount: {
      type: String,
      default: ""
    },
    paymentData: {
      type: Object,
      default: () => { },
    },
    userToken: {
      type: String,
      default: ""
    }
  },

  data: function() {
    return {
      componentType: "",
      publicKey: "",
      supportedGateways: [],
      clientIpAddress: "",
      gatewaySettingsResults: {status: {success: true, msg: ''}, gatewaySettings: {}},
      currencyInfoResult: {code: "", symbol:""},
      embeddedPaymentStore,
      conditionalLogicStateStore,
    };
  },
  computed: {
    paymentComponent() {
      return this.getComponentName(this.componentType);
    },

    shouldRetrieveModuleData() {
      return this.useSystemDefinedGateway;
    },

    useEmbeddedModule() {
      return ((this.gfDesign.length > 0) && this.useSystemDefinedGateway);
    }
  },
  watch: {
    additionalProperties: {
        handler: function(val){
            if(!val) return;
            const view = casingUtilities.objectKeysToCamelCase(val.view);
            if(view && view.pages)
            this.conditionalLogicStateStore.initializeStateViaPages(view.pages);
        },
        immediate: true,
        deep: true,
    },
  },
  created(){
    this.embeddedPaymentStore.initializeStore();
    this.embeddedPaymentStore.setDemo(this.demo);
    this.embeddedPaymentStore.setUserToken(this.userToken);
  },
  async mounted() {
    this.componentType = this.type;
    this.publicKey = this.tokenizationKey;
    if(this.paymentAmount !== "") {
      this.embeddedPaymentStore.updateAmount(this.paymentAmount);
    }

    try {
        if (localStorage && localStorage["GfDebug"] === "true")
            logger.debug = true;
    } catch(_) {
      // ignored. Cant access localStorage. Possibly cookies disabled
    }

    logger.logInfo(`GfPayments mounted, this.additionalProperties:`, this.additionalProperties);

    this.embeddedPaymentStore.initializeCustomQuestions(this.additionalProperties.view.pages);
    logger.logInfo(`GfPayments, this.cdnBaseUrl: ${this.cdnBaseUrl}`);
    this.embeddedPaymentStore.setCdnBaseUrl(this.cdnBaseUrl);
    this.embeddedPaymentStore.setIsPreview(this.isPreview);
    logger.logInfo(`useSystemDefinedGateway: ${this.useSystemDefinedGateway}`)
    GfLocalisation.initLocale('paymentModule')
    GfPaymentServiceClient.setApiDomain(this.apiDomain)

    if (this.shouldRetrieveModuleData) {
      var results = await GfPaymentServiceClient.retrievePaymentModuleData(this.beneficiaryId, this.eventId, this.type);
      logger.logInfo('Loading using Gateway Settings: ', results);
      this.gatewaySettingsResults = results;
      this.currencyInfoResult = results.gatewaySettings.currency;

      if (results.status.success) {
        this.onPaymentDataReceived(results.gatewaySettings);
      }
    }

  },

  methods: {
    onPaymentDataReceived(results) {
      // TODO: this all needs to be implemented. But hardcoded for now
      results.gatewayTypes.forEach(element => {
          if (element.additionalSettings) {
              element.additionalSettings = JSON.parse(element.additionalSettings);
          }
      });
      this.clientIpAddress = results.clientIpAddress;
      this.supportedGateways = results.gatewayTypes;
      if (this.onFeesReceived !== "" && this.onFeesReceived) {
        window[this.onFeesReceived](results.fees);
      }
    }
  }
}
</script>

<style lang="css" scoped></style>
